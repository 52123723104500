import React from 'react'
import Auth from '../components/Auth'
import { ActivityIndicator } from '../components/generic'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import theme from '../theme.style'
import { ThemeProvider } from 'emotion-theming'
import { rmaLogo } from '../images'

export const useTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#00bce7",
    secondary: "#fbad17",
  },
  logo: rmaLogo
}

const errorMap = {
  NoEmployee: "Error: We do not recognise your email address. Please send an email to matlasupport@meerkat.co.za",
}


function SignInPage() {
  if (typeof window === 'undefined') {
    return  <ThemeProvider theme={useTheme}>
      <ActivityIndicator />
    </ThemeProvider>
  }

  const error = new URLSearchParams(window.location.search).get('error') || ""   

  return <ThemeProvider theme={useTheme}>
    <Layout noHeader noMoku noFooter>
      <SEO title="Salesperson Sign In" noTemplate />
      {error && <center>{errorMap[error] || "Error: An Unknown Error has occurred - contact matlasupport@meerkat.co.za"}</center>}
      <Auth redirect="/sales" azureAuth login authClientId="websales" />
      
    </Layout>
  </ThemeProvider>
}

export default SignInPage